import { Box } from "grommet";

const youtubeIds = [
  "sKcTJNrvcY0",
  "UADldwDwIYg",
  "6xOgBqZM0Mo",
  "pS2-G4yqvd8",
  "WxJh4MitBms",
  "V_APmxaSDPc",
];

const Testemunhos = () => {
  return (
    <Box
      pad={{ bottom: "xlarge", top: "medium" }}
      margin={"auto"}
      justify="center"
      gap="medium"
      width="large"
    >
      {youtubeIds.map((id) => (
        <iframe
          key={id}
          width="560"
          height="315"
          src={`https://www.youtube-nocookie.com/embed/${id}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      ))}
    </Box>
  );
};

export default Testemunhos;
