import Home from "./containers/Home";
import theme from "./theme";
import Navbar from "./components/Navbar";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Box, Grommet, Main } from "grommet";
import styled from "styled-components";
import Comunicado from "./containers/Comunicado";
import Links from "./containers/Links";
import Testemunhos from "./containers/Testemunhos";

const StyledMain = styled(Main)`
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
`;

const App = () => (
  <Grommet theme={theme} plain>
    <StyledMain>
      <Box pad={{ left: "large", right: "large" }}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/comunicado-de-imprensa" element={<Comunicado />} />
            <Route path="/links" element={<Links />} />
            <Route path="/testemunhos" element={<Testemunhos />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </StyledMain>
  </Grommet>
);

export default App;
