import { Box, Text } from "grommet";
import styled from "styled-components";

const TextBlock = styled(Text)`
  color: #fff;

  a {
    color: #000;
  }
`;

const Comunicado = () => {
  return (
    <>
      <Box
        pad={{ bottom: "xlarge", top: "medium" }}
        margin={"auto"}
        justify="center"
        gap="medium"
        width="large"
      >
        <TextBlock>
          Ontem, dia 2 de maio, o Centro Social de Santa Engrácia, em Lisboa,
          ocupado desde o dia 25 de Abril pelo movimento OkuparAbril, foi
          despejado e encerrado pela PSP sem ordem emitida judicialmente. As
          autoridades recusaram qualquer diálogo com o advogado do Centro.
          Centenas de pessoas de todas as idades incluindo moradores juntaram-se
          em solidariedade. Prometem lutar e garantem que o sonho não acabou:
          pelas ruas e pela internet circula já{" "}
          <a
            href="https://peticaopublica.com/?pi=PT120716"
            target="_blank"
            rel="noreferrer"
          >
            uma petição pública
          </a>
          .
        </TextBlock>
        <TextBlock>
          O movimento que protesta contra a especulação imobiliária e que conta
          com o apoio de moradores e vizinhos do bairro, expõe a ausência de
          espaços colectivos e comunitários na cidade, apontando a falta de
          cumprimento dos estatutos da Fundação D. Pedro IV de carácter social
          (IPSS), gestora do edifício que está previsto ser demolido para
          construção de habitações privadas.
        </TextBlock>
        <TextBlock>
          O edifício situado na Rua Bartolomeu da Costa nº 2, propriedade da
          Fundação D. Pedro IV e devoluto há 15 anos, foi ocupado no dia 25 de
          Abril de 2024, invocando a luta popular do PREC e abrindo portas como
          Centro Social e Cultural de Santa Engrácia.
        </TextBlock>
        <TextBlock>
          O movimento surge em protesto com a falta de coerência dos estatutos
          da Fundação cujo património imobiliário, doado em grande parte pelo
          Estado e pela CM Lisboa para fins sociais, está agora a ser
          direccionado para “um condomínio de habitação para a classe média”,
          segundo Vasco Canto Moniz, Presidente do Conselho de Administração da
          Fundação D. Pedro IV. OkuparAbril expõe a Fundação já previamente
          implicada em vários esquemas de corrupção e anteriormente acusada de
          <a
            href="https://www.rtp.pt/noticias/pais/centenas-de-moradores-de-chelas-na-rua-contra-fundacao-d-pedro-iv_n42963"
            target="_blank"
            rel="noreferrer"
          >
            "terrorismo social" na gestão de habitações de vários bairros em
            Lisboa
          </a>
          .
        </TextBlock>
        <TextBlock>
          Em resposta, propõe-se cumprir a função social que a própria Fundação
          negligencia, criando um espaço coletivo de apoio mútuo, comunitário e
          multicultural. A ocupação de um edifício devoluto para criar um espaço
          comunitário vem no seguimento da crescente pressão imobiliária na
          cidade e subsequente despejo de vários coletivos e associações,
          reivindicando o direito à habitação e à cidade acima do lucro e da
          especulação.
        </TextBlock>
        <TextBlock>
          Demonstrando a força da vontade popular, durante uma semana, este
          centro juntou centenas de pessoas diariamente, organizando concertos,
          debates, projeções de filmes, oficinas, performances, assembleias e
          cantinas populares diárias, ações de limpeza do jardim e da casa,
          atividades para crianças, jogos de tabuleiro, planeando bibliotecas
          populares e hortas comunitárias. Foram várias as reuniões com a
          vizinhança onde se partilharam sonhos, vontades e necessidades da
          comunidade. Quiseram despejar o centro social, sem aviso e sem
          diálogo, mas não conseguirão despejar uma ideia, um sonho e a vontade
          coletiva de criar um espaço auto-gerido de apoio mútuo aberto à
          população.
        </TextBlock>
        <TextBlock>
          Hoje, 50 anos após o 25 de Abril, este movimento volta a invocar o
          PREC, a união e a luta popular contra os interesses do capital,
          fazendo o chamado a uma reapropriação colectiva das nossas vidas, dos
          nossos bairros, das nossas cidades, dos nossos campos. Afirma:
          "Queremos habitação digna e acessível para todos, queremos espacos
          colectivos fora da lógica do capital e da especulação, queremos o
          direito à cidade, à casa, ao lugar e à terra como espaço incondicional
          de existência no mundo. Hoje, continua em marcha o PROC, Processo
          Revolucionário de Okupação em Curso."
        </TextBlock>
        <TextBlock>
          No{" "}
          <a
            href="https://www.instagram.com/p/C6McjqOCOtk/?igsh=MTdlbHR0Zmw4MmdndA=="
            target="_blank"
            rel="noreferrer"
          >
            manifesto publicado pelo OkuparAbril no 25 de Abril
          </a>
          , pode ler-se: "Somos muitas e diversas, vimos de todas as partes.
          Somos trabalhadores, estudantes, precárias, queers, operárias,
          músicos, filósofos, artistas, cientistas, camponeses, migrantes,
          viajantes, rebeldes e insurgentes da resistência. Continuamos a sonhar
          com um mundo onde caibam muitos mundos, onde o povo é quem mais
          ordena" e por cada despejo, reivindicam mil ocupações!
        </TextBlock>
      </Box>
    </>
  );
};

export default Comunicado;
