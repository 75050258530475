import { Box, Heading } from "grommet";
import styled from "styled-components";

const Link = styled.a`
  color: #eee;
`;

const Comunicado = () => {
  return (
    <>
      <Box
        pad={{ bottom: "xlarge", top: "medium" }}
        margin={"auto"}
        justify="center"
        gap="medium"
        width="large"
      >
        <Heading color="white" level={3} margin={{ bottom: "xsmall" }}>
          Petição Pública
        </Heading>
        <Link
          href="https://peticaopublica.com/pview.aspx?pi=PT120716"
          target="_blank"
          rel="noreferrer"
        >
          Assina aqui a petição pública
        </Link>
        <Heading color="white" level={3} margin={{ bottom: "xsmall" }}>
          Fundação D. Pedro IV
        </Heading>
        <Link
          href="https://www.rtp.pt/noticias/pais/centenas-de-moradores-de-chelas-na-rua-contra-fundacao-d-pedro-iv_n42963"
          target="_blank"
          rel="noreferrer"
        >
          Centenas de moradores de Chelas na rua contra Fundação D. Pedro IV
        </Link>
        <Link
          href="https://expresso.pt/actualidade/fundacao-d-pedro-iv-perde-bairro-dos-loios-e-das-amendoeiras=f110332"
          target="_blank"
          rel="noreferrer"
        >
          Fundação D. Pedro IV perde bairro dos Lóios e das Amendoeiras
        </Link>
        <Link
          href="https://www.rtp.pt/noticias/pais/moradores-loios-e-amendoeiras-satisfeitos-com-recomendacao-ao-governo-sobre-fundacao-d-pedro-iv_n46422"
          target="_blank"
          rel="noreferrer"
        >
          Moradores Lóios e Amendoeiras satisfeitos com recomendação ao Governo
          sobre Fundação D. Pedro IV
        </Link>
        <Heading color="white" level={5} margin={{ bottom: "xsmall" }}>
          Reportgem - Fundação D. Pedro IV - Fundação Intocável
        </Heading>

        <Link
          href="https://www.youtube.com/watch?v=eBX7-IAXSnI"
          target="_blank"
          rel="noreferrer"
        >
          Parte 1
        </Link>

        <Link
          href="https://www.youtube.com/watch?v=4AYQq2HD5a0"
          target="_blank"
          rel="noreferrer"
        >
          Parte 2
        </Link>

        <Link
          href="https://www.youtube.com/watch?v=79TbYowB_XQ"
          target="_blank"
          rel="noreferrer"
        >
          Parte 3
        </Link>
      </Box>
    </>
  );
};

export default Comunicado;
