import { Box, Image, Text } from "grommet";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TextBlock = styled(Text)`
  color: #fff;

  a {
    color: #fff;
  }
`;

const Iframe = styled.iframe`
  max-width: 100%;
`;

const Home = () => (
  <>
    <Box
      pad={{ bottom: "xlarge", top: "medium" }}
      margin={"auto"}
      justify="center"
      gap="medium"
      width="large"
    >
      <Iframe
        title="Okupa Abril from Left Hand Rotation"
        src="https://player.vimeo.com/video/942706388?h=9dbfdde632"
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></Iframe>
      <TextBlock>
        Hoje, 50 Anos após o 25 de Abril, vemos ser posto em causa o direito
        fundamental à habitação e a espaços colectivos de apoio mútuo e lazer,
        longe da gentrificação e massificação do turismo em Lisboa (e outros
        lugares). Vemos lucros subirem e direitos desaparecerem sob o fantasma
        do fascismo e os tentáculos da especulação.
      </TextBlock>
      <TextBlock>
        Hoje, 50 anos após o 25 de Abril, lembramos o PREC, Periodo
        Revolucionário em Curso, lembramos as ocupações, as comissões de
        moradores, as assembleias populares, a reforma agrária, as
        coletivizações e ocupações de terras e fábricas onde a revolução se
        concretizou e os sonhos construíram alternativas de futuro. Lembramos um
        povo unido que se organizou e ocupou as ruas, praças e espaços onde
        sonhou ser livre.
      </TextBlock>
      <TextBlock>
        Hoje, invocamos esta luta para nos REAPROPRIARMOS das nossas vidas, dos
        nossos bairros, das nossas cidades, dos nossos campos, montanhas e rios.
        Reivindicamos espaços para viver, habitar, comer, dormir, conviver,
        amar, dançar, debater, criar, sonhar, ler, escutar, ver, aprender,
        construir e existir fora da lógica da guerra e do capital. Queremos
        espaços colectivos, comunitários e multiculturais, ateliers, rádios,
        bibliotecas, concertos, cinemas, exposições e cantinas populares sem os
        fantasmas da especulação. Queremos habitação digna e acessível para
        todos, queremos o direito à cidade, à casa, ao lugar e à terra como
        espaço de existência incondicional no mundo.
      </TextBlock>

      <Slider
        autoplay
        autoplaySpeed={4000}
        centerMode
        infinite
        slidesToScroll={1}
        slidesToShow={1}
        variableWidth
      >
        {Array.from(Array(25).keys()).map((_, index) => (
          <Box key={index} pad={"xsmall"}>
            <Image alt="" src={`/images/${index + 1}.jpg`} height={280} />
          </Box>
        ))}
      </Slider>
      <TextBlock>
        Somos muitas e diversas: vimos de todas as partes. Somos trabalhadores,
        estudantes, precárias, queers, operárias, músicos, filósofos, artistas,
        cientistas, camponeses, migrantes, viajantes, rebeldes e insurgentes da
        resistência.
      </TextBlock>
      <TextBlock>
        Sonhamos com um mundo onde caibam muitos mundos, onde o povo é quem mais
        ordena.
      </TextBlock>
      <TextBlock>
        Este edifício, antiga sede da Fundação D. Pedro IV, localizado no centro
        do bairro de Santa Engrácia, está abandonado há cerca de 15 anos. Uma
        fundação de carácter social (IPSS) cuja propriedade querem transformar
        em mais um condomínio de luxo, ao serviço da especulação. Uma Fundação
        cujo património imobiliário, doado em grande parte pelo Estado e pela CM
        Lisboa, não está hoje aberto para a população, não cumprindo os
        estatutos da própria Fundação.
      </TextBlock>
      <TextBlock>
        Hoje, 50 anos após o 25 de Abril, propomos reapropriar-nos de um espaço
        para co-criação coletiva, comunitária e multicultural. Hoje, pomos em
        marcha o PROC, Processo Revolucionário de Okupação em Curso.
      </TextBlock>
    </Box>
  </>
);

export default Home;
