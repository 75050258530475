import { Box, Header, Heading, Image } from "grommet";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 34px;
  justify-content: center;
  margin: 0 auto 16px;
  width: 100%;
`;

const HeaderLink = styled(Link)`
  align-items: center;
  color: #fff;
  display: flex;
  text-decoration: none;
`;

const Logo = styled(Image)`
  max-width: 120px;
  margin-bottom: 16px;
  margin-right: 16px;
`;

const Title = styled(Heading)`
  span {
    padding: 6px 8px 2px;
    background-color: #2b3513;
  }
`;

const Menu = styled.menu`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: space-evenly;
  margin: 0 auto 32px;
  padding: 16px 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const MenuLink = styled(Link)<{ active: boolean }>`
  color: #fff;
  display: inline-flex;
  font-size: 16px;
  margin: 0 16px;
  text-decoration: none;
  text-transform: uppercase;

  span {
    display: inline-block;
    padding: 6px 4px 2px;
  }

  @media (max-width: 600px) {
    margin: 4px 16px;
    align-self: center;
  }

  ${(props) =>
    props.active &&
    css`
      color: #fff;

      span {
        background-color: #2b3513;
      }
    `}

  :hover {
    color: #fff;

    span {
      background-color: #2b3513;
    }
  }
`;

const navLinks = [
  {
    content: "Manifesto",
    route: "/",
  },
  {
    content: "Comunicado de Imprensa",
    route: "/comunicado-de-imprensa",
  },
  {
    content: "Testemunhos",
    route: "/testemunhos",
  },
  {
    content: "Links",
    route: "/links",
  },
];

const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <>
      <Header>
        <Wrapper>
          <Logo src={"/logo.png"} />
          <HeaderLink to="/">
            <Title level={1}>
              <span>Centro Cultural de Santa Engrácia</span>
            </Title>
          </HeaderLink>
        </Wrapper>
      </Header>

      <Box width="large" margin="auto">
        <Menu>
          {navLinks.map(({ content, route }) => {
            return (
              <MenuLink active={pathname === route} to={route} key={route}>
                <span>{content}</span>
              </MenuLink>
            );
          })}
        </Menu>
      </Box>
    </>
  );
};
export default Navbar;
